<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap>
      <v-flex xs12 sm12>
        <v-card outlined class="primary--border">
          <v-card-title class="title">
            Print Entrance Card
            <v-spacer></v-spacer>

            <v-flex xs2 v-if="form.items.data.length">
              <add-button
                v-if="$auth.can('admit-card-generate-bulk')"
                @action="printBulk"
                :permission="'section-read'"
                icon="print"
                >Print All
              </add-button>
              <!-- <v-btn outlined @click="printBill" ma-0 small>Print</v-btn> -->
              <!--<refresh-button v-show="form.items.data.length" @action="get()"></refresh-button>-->
            </v-flex>
          </v-card-title>
          <v-card outlined>
            <v-card-title class="title pb-0">
              <v-flex xs3 sm4>
                <v-select
                  :loading="examLoading"
                  :disabeld="!exams.length"
                  :items="exams"
                  class="pa-0"
                  label="Exam"
                  v-model="exam"
                  outlined
                  dense
                />
              </v-flex>
              <v-flex xs3 sm3>
                <v-select
                  :loading="gradeLoading"
                  :items="grades"
                  class="pa-0"
                  label="Grade"
                  v-model="grade"
                  :disabled="!exam || !grades.length"
                  outlined
                  dense
                />
              </v-flex>
              <v-flex xs3 sm3>
                <v-select
                  :items="sections"
                  class="pa-0"
                  label="Section"
                  v-model="section"
                  :disabled="!grade"
                  outlined
                  dense
                />
              </v-flex>
            </v-card-title>
          </v-card>

          <v-data-table
            :headers="headers"
            :items="form.items.data"
            :options.sync="pagination"
            :footer-props="footerProps"
            :server-items-length="form.items.meta.total"
            :loading="form.loading"
            class="marks_entry"
          >
            <template v-slot:item="{ index, item }">
              <tr>
                <td>{{ index + form.items.meta.from }}</td>
                <td class="text-xs-left">{{ item.name }}</td>
                <td class="text-xs-center">
                  {{ item.roll }}
                </td>
                <td class="text-right">
                  <download-button
                    @action="printSingle(item.enroll_id)"
                    v-if="$auth.can('admit-card-generate-single')"
                    >Print</download-button
                  >
                </td>
              </tr>
            </template>
            <v-alert
              slot="no-results"
              :value="true"
              color="error"
              icon="warning"
            >
              Your search for "{{ search }}" found no results.
            </v-alert>
          </v-data-table>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import { mapState } from "vuex";
import Form from "@/library/Form";
import Mixins from "@/library/Mixins";

export default {
  mixins: [Mixins],
  data: () => ({
    examLoading: false,
    gradeLoading: false,
    subjectLoading: false,
    form: new Form(
      {
        grade_id: "",
        subject_id: "",
        section_id: "",
        exam_id: "",
        students: [],
        is_verify: 0,
      },
      "/api/search-student"
    ),
    search: null,
    pagination: {
      sortBy: ["id"],
      itemsPerPage: 10,
    },
    rowsPerPageItems: [5, 10, 25, 50, 75],
    is_verify: 0,
    dataVerfiy: false,
    subject: "",
    subjects: [],
    section: "",
    sections: [],
    exam: "",
    exams: [],
    grade: "",
    grades: [],
    subject_marks: {},
    headers: [
      { text: "SN", align: "left", value: "id", width: 50, sortable: false },
      { text: "Name", align: "left", value: "name", sortable: false },
      { text: "Roll No", align: "left", value: "roll", sortable: true },
      { text: "Action", align: "right", value: "theory", sortable: false },
    ],
  }),
  computed: {
    ...mapState(["batch"]),
    subjectName() {
      let subjectName = "";
      if (this.subject) {
        let $this = this;
        this.subjects.map(function(subject) {
          if (subject.value === $this.subject) subjectName = subject.text;
        });
      }
      return subjectName;
    },
    absentStudentsCount() {
      let count = 0;
      this.form.items.data.map((item) => {
        if (item.theory === "Absent" || item.practical === "Absent") {
          count++;
        }
      });
      return count;
    },
  },
  mounted() {
    this.getExams();
  },
  watch: {
    pagination: function() {
      this.get();
    },
    batch: function(value) {
      this.get();
    },
    grade: function() {
      this.section = "";
      this.subject = "";
      this.form.items.data = [];
      this.getSections();
    },
    exam: function() {
      this.grade = "";
      this.form.items.data = [];
      this.getGrades();
    },
    subject: function() {
      this.form.items.data = [];
    },
    section: function() {
      this.get();
    },
  },

  methods: {
    get(params) {
      if (this.section) {
        let extraParams =
          "&gradeId=" + this.grade + "&sectionId=" + this.section+"&slim=true";
        let query = [null, undefined].includes(params)
          ? this.queryString(extraParams)
          : params;
        this.form.get(null, query).then(({ data }) => {
          this.pagination.totalItems = data.meta.total;
        });
      }
    },
    getGrades() {
      this.gradeLoading = true;
      this.$rest
        .get(
          "/api/exam-grade?rowsPerPage=25&descending=true&slim=true&examId=" +
            this.exam
        )
        .then(({ data }) => {
          this.grades = data.data.map((item) => {
            return { value: item.grade_id, text: item.name };
          });
        })
        .finally(() => {
          this.gradeLoading = false;
        });
    },
    getExams() {
      this.examLoading = true;
      this.$rest
        .get("/api/exam")
        .then(({ data }) => {
          this.exams = data.data.map((item) => {
            return { value: item.id, text: item.name };
          });
        })
        .finally(() => {
          this.examLoading = false;
        });
    },
    getSections() {
      this.$rest
        .get("/api/section?batch=" + this.batch.id + "&grade=" + this.grade)
        .then(({ data }) => {
          this.sections = data.data.map((item) => {
            return { value: item.id, text: item.name };
          });
        });
    },
    printSingle(enrollId) {
      this.$rest.get("/api/print/entrance-card").then(({ data }) => {
        let url =
          data.data.download_url +
          "?examId=" +
          this.exam +
          "&sectionId=" +
          this.section +
          "&gradeId=" +
          this.grade +
          "&enrollId=" +
          enrollId;
        window.open(url);
      });
    },
    printBulk() {
      this.$rest.get("/api/print/entrance-card").then(({ data }) => {
        let url =
          data.data.download_url +
          "?examId=" +
          this.exam +
          "&sectionId=" +
          this.section +
          "&gradeId=" +
          this.grade;
        window.open(url);
      });
    },
  },
};
</script>
<style lang="scss">
.marks_entry {
  input[disabled] {
    background-color: #eee;
    color: #ccc;
    cursor: not-allowed;
  }
  .mark_entry_input {
    border: 1px solid #ccc;
    font-weight: bold;
    border-radius: 3px;
    width: 60px;
    height: 25px;
    text-align: center;
  }
  .marks_entry_input_container {
    display: flex;
    align-items: center;
    justify-content: center;
    .v-input__slot {
      margin-bottom: 0 !important;
    }
    .v-input--selection-controls:not(.v-input--hide-details) .v-input__slot {
      margin-bottom: 0 !important;
    }
    .v-input__control {
      display: -webkit-box !important;
      -webkit-box-orient: horizontal !important;
    }
    .v-input {
      -webkit-box-align: start;
      -ms-flex-align: start;
      align-items: flex-start;
      display: -webkit-box !important;
      display: -ms-flexbox;
      /* display: flex; */
      /* -webkit-box-flex: 1; */
      -ms-flex: 1 1 auto;
      flex: none;
      font-size: 16px;
      text-align: left;
    }
  }
  input:focus {
    outline: none;
  }
}
</style>
